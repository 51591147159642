@charset 'UTF-8';

@include PC {
	img{
		pointer-events: none;
	}
	a{
		img{
			pointer-events: initial;
		}
	}

}

@include min-screen(768px) {
	a.inview:hover{opacity: 0.5;}
}

[class*="txt"]{
	text-align: justify;
}

.btn-more{
	padding-bottom: 30px;
	position: relative;
	.loading_status{
		opacity: 0;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		text-align: center;
	}
}


@include max-screen(350px) {
	.no-break-ip5{
		br{
			display: none;
		}
	}
}

.c-title01{
	line-height: 40px;
	font-weight: bold;
	@include letter-spacing(150);
	@include font-size(24);
	@include SP{
		line-height: 1.5625;
		@include letter-spacing(80);
		@include font-size(16);
	}
	@include max-screen(350px) {
		@include font-size(13);
	}
}

.c-title02{
	line-height: 31px;
	font-weight: bold;

	@include ffLT;
	@include letter-spacing(100);
	@include font-size(26);
	&.line{
		border-bottom: 1px solid $maincolor;
	    padding-bottom: 24px;
	}
	@include SP{
	    line-height: 18px;
		@include font-size(15);
		&.line{padding-bottom: 9px;}
	}

}

.c-title03{
	@include PC {
		cursor: vertical-text;
		// direction: rtl;
		writing-mode: vertical-rl;
		-o-writing-mode: vertical-rl;
		-ms-writing-mode: vertical-rl;
		-ms-writing-mode: tb-rl;
		-moz-writing-mode: vertical-rl;
		-webkit-writing-mode: vertical-rl;
		text-orientation: sideways-right;
		-o-text-orientation: sideways-right;
		-ms-text-orientation: upright;
		-ms-text-orientation: sideways-right;
		-moz-text-orientation: sideways-right;
		-webkit-text-orientation: sideways-right;
		
	}
	line-height: 31px;
	position: relative;
	font-weight: bold;
	@include ffLT;
	@include font-size(26);
	@include letter-spacing(100);
	@include SP {
		line-height: 18px;
		@include font-size(15);
		&:after{
			position: relative;
			content: "";
			display: block;
			background: #000;
			width: 16px;
			height: 1px;
			background: $maincolor;
		    margin-top: 33px;
		    margin-left: 3px;
		    margin-bottom: 32px;
		}
	}
}

.c-title04{
	@include letter-spacing(150);
	@include font-size(20);
	@include SP{
		font-weight: bold;
		@include letter-spacing(100);
		@include font-size(16);
	}
}

.c-btn1{
	line-height: 37px;
	text-align: center;
	border: 1px solid $maincolor;
	max-width: 220px;
	width: 100%;
	display: block;
	font-weight: bold;
	position: relative;
	@include ffLT;
	@include font-size(15);
	@include letter-spacing(70);
	&.center{
		margin: 0 auto;
	}
	@include PC {
		
		&:hover{
			cursor: pointer;
			opacity: 0.5 !important;
			transition-delay: 0s !important;
		}
	}
	@include SP{
		max-width: 293px;
		margin: 0 auto;
		@include font-size(12);
	}
	@include max-screen(420px) {
		max-width: 420px;
	}
}

.bg-parallax{
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: center;
	// background-size: 120%;
	// transition: .1s ease;
	// @include max-screen(1100px) {
	// 	&.sp{
	// 		display: block !important;
	// 	}
	// 	&.pc{
	// 		display: none !important;
	// 	}
	// }
}

.concept_image{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../image/top/concept-image-sp.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

// section

.subpage{
	.c-title02.line{
		@include PC {padding-bottom: 20px;}
	}
}

.c-inner{
	@include SP {
		padding-left: 20px;
		padding-right: 20px;
	}
	@include max-screen(360px) {
		padding-left: 0;
		padding-right: 0;
	}
}

.c-cms-content{
	max-width: 624px;
	margin: 0 auto;
	.c-title01{
		margin-top: 51px;
		margin-bottom: 40px;
	}
	.photo{
		text-align: center;
	}
	a{
		color: #004498;
		text-decoration: underline;
		&:hover{
			text-decoration: none;
		}
	}
	h2,h3,h4,h5,h6{
		font-weight: bold;
	}
	@include SP {
		
		.c-title01{
			margin-top: 32px;
		    margin-bottom: 24px;
		}
		.txt{
			@include letter-spacing(100);
		}
	}
}

.cmn-list{
	li{
		&:first-of-type{border-top: 1px solid $maincolor;}
		border-bottom: 1px solid $maincolor;
	}
	a{
		padding: 22px 0 20px;
		@include flexbox();
	}
	span,small{
		@include letter-spacing(200);
	}
	small{
		min-width: 50px;
		@include font-size(12);
	}
	span{
		margin-left: 10px;
		position: relative;
		@include font-size(16);
	}
	&.list-news{
		li.active{
			position: relative;
			&:before{
				position: absolute;
				content: "";
				width: 16px;
				height: 16px;
				background: #62b7e7;
				border-radius: 50%;
				top: 50%;
				transform: translateY(-50%);
				left: -33px;
				margin-top: -1px;
			}
		}
	}
	&.date{
		small{min-width: 91px;}
		span{
			margin-left: 58px;
		    height: 29px;
		    overflow: hidden;
			@include font-size(16);
		    -webkit-line-clamp: 1;
		    display: -webkit-box;
		    -webkit-box-orient: vertical;
		    -o-text-overflow: ellipsis;
		    text-overflow: ellipsis;
		    white-space: normal;
		}
	}
	@include min-screen(768px) {
		@include max-screen(1080px) {
			&.list-news{
				padding: 0 25px;
			}
		}
	}
	@include SP{
		a{padding: 16px 0 18px;}
	    small,span{
	    	line-height: 15px;
	    	height: 15px;
	    	overflow: hidden;
			@include letter-spacing(200);
	    	@include font-size(11);
	    }
	    small{min-width: 33px;}
	    span{margin-left: 0;}
		&.date{
			a{
			    padding: 18px 0 16px;
				line-height: 15px;
			}
			small{
				min-width: 80px;
				@include letter-spacing(130);
				@include font-size(9);
			}
			span{
				margin-left: 0;
				height: 15px;
				@include letter-spacing(80);
				@include font-size(14);
			}
		}
		&.list-news{
			li.active:before{
				width: 10px;
				height: 10px;
				left: -20px;
			}
		}
	}
	@include max-screen(360px) {
		.tops{
			padding: 0 10px;
		}
		&.list-news{
			padding: 0 10px;
			li.active:before{
				left: -20px;
			}
		}
	}
}