@charset 'UTF-8';

.tab {
	@include min-screen(1024px) {display: none;}
	@include max-screen(767px) {display: none;}
}
.taC {text-align: center;}

.footer{
	background: $blue1;
	color: #fff;
	padding: 38px 0 25px;
	.wcm{max-width: 1080px;}
	.logo{
		max-width: 160px;
		margin: 0 auto;
		img{
			min-height: 134px;
			width: 100%;
		}
	}
	.social{
		margin-top: 56px;
		@include flexbox;
		@include justify-content(center);
		@include align-items(center);
		a{margin: 0 21px;}
	}
	.contact{
		font-weight: bold;
		text-align: center;
	    margin-top: 44px;
	    text-indent: 4px;
	    @include font-size(14);
		@include letter-spacing(200);
		a{color: #fff;}
		strong{
			@include ffLT;
			@include font-size(16);
			@include letter-spacing(100);
		}
	}
	.bottom-content{
			margin-top: 32px;
			line-height: 22px;
			padding: 0 10px;
			@include font-size(14);
			@include letter-spacing(20);
			@include flexbox;
			@include flex-wrap(wrap);
			@include min-screen(1080px) {
			@include flex-direction(row-reverse);
		}
	}
	.nav{
        margin-left: 10px;
		@include flexbox;
		a{
			color: #fff;
			padding-right: 10px;
			padding-left: 10px;
		}
		small{
			display: none;
		}
		li{
			&:first-of-type:before,&:after{
					content: "|";
					display: inline-block;
					position: relative;
					vertical-align: middle;
					top: -1px;
			}
		}
	}
	@include min-screen(1100px) {
		.bottom-content{
			@include justify-content(center);
		}
		.nav{
		}
	}
	@include max-screen(1100px) {
		@include min-screen(768px) {
		.bottom-content{
			@include justify-content(center);
		}
		.nav{
				margin: 0;
				margin-bottom: 10px;
				width: 100%;
				align-content: center;
				justify-content: center;
			}
		}
	}
	@include SP {
		padding: 18px 0 30px;
		.wcm{
			padding: 0 10px;
		}
		.logo{
			max-width: 120px;
			img{
				height: 100px;
			}
		}
		.social{
			margin-top: 25px;
			a{margin: 0 16px;}
			img{width: 32px;}
		}
		.contact{
		    margin-top: 38px;
			line-height: 17px;
			@include font-size(11);
			strong{@include font-size(12);}
		}
		.bottom-content{
		    display: block;
		    margin-top: 20px;
			line-height: 11px;
			@include font-size(11);
		}
		.nav{
			margin: 0;
			margin-bottom: 10px;
			@include flex-wrap(wrap);
			@include justify-content(center);
			li{margin-bottom: 10px;}
			a{
		        padding-right: 4px;
			    padding-left: 4px;
		        display: inline-block;
			    vertical-align: middle;
			}
			li:first-of-type:before{margin-left: 3px;}
		}
		.copyright{
			line-height: 16px;
			text-align: center;
			letter-spacing: 0;
			@include font-size(8);
		}
	}
	@include max-screen(350px) {
		.social a{
			margin: 0 10px;
		}
		.nav{
			@include font-size(9);
		}
		.copyright{
			font-size: 8px !important;
		}
	}
	.hide-jp{display: none;}
}

.en{
	.footer{
		.hide-jp{
			display: block;
			span{
				display: inline-block;
			}
		}
		.hide-en{display: none;}
		*[data-english]  {
			&:after{
				display: inline-block;
				content: attr(data-english);
				position: relative;
				@include SP {
					height: 11px;
				}
			}
			span{display: none;}
		}
		.bottom-content{
			@include PC {
				margin-top: 25px;
			}
			@include SP {
				padding: 0;
			}
		}

		.nav li:after,.nav li:first-of-type:before,.nav li,.nav li a, .nav li a span{
			
			@include SP {
				
			}
		}

		.nav li:after,.nav li:first-of-type:before{
			display: none;
		}
		@include PC {
			.nav li:after,.nav li:first-of-type:before{
				top: -1px;
			}
		}
		@include SP {
			.nav li:after,.nav li:first-of-type:before{
				top: -1px;
			}
		}
		.nav{
			small{
				display: inline-block;
				position: relative;
				left: -10px;
				@include SP {
					top: -1px;
					left: -4px;
				}
				@include max-screen(360px) {
					left: -3px;
				}
			}
			span + small{
				left: auto;
				right: -10px;
				@include SP {
					right: -4px;
				}
				@include max-screen(360px) {
					right: -3px;
				}
			}
			a{
				display: inline-block;
			}
			@include SP {
				li:after{
					top: -1px;
				}
				li:before{
					top: -1px;
					margin-left: 0;
				}
		    a{
		    	@include font-size(10);
			    letter-spacing: 0.06em;
			    padding: 0 4px;
		    }
			}
			@include max-screen(360px) {
				a{
					padding: 0 3px;
					@include font-size(9);
				}
			}
		}
		.copyright{
			letter-spacing: 0;
			@include SP {
				padding: 0 2px;
			}
		}
	}
}