@charset 'UTF-8';

.calling {
  a {
    pointer-events: none;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  .tel {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 2px 10px 2px 20px;
    position: relative;
    font-family: Arial, sans-serif;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 16px;
      height: 21px;
      margin-top: -10px;
      background: url(../img/common/icon/ico_tel.svg) no-repeat 0 50%;
      background-size: 16px 21px;
    }
  }

  .time {
    font-size: 12px;
  }

  .make {
    display: none;
    font-size: 12px;
  }

  @include max-screen(767px) {
    .make,
    .time {
      display: block;
    }
  }
}

@media screen and (max-width : 767px) and (max-device-width : 768px) {
  .calling {
    a {
      pointer-events: auto;
    }
  }
}

body:not(.subpage) #logo{
    opacity: 1;
}

.header {
  position: absolute;
  left: auto;
  top: auto;
  z-index: 1000;
  width: 100%;
  background: #fff;
  #logo {
    max-width: 128px;
    width: 100%;
    position: absolute;
    top: 24px;
    left: 48px;
    transition: left .3s ease-in-out, opacity .3s .0s ease;
    cursor: pointer;
    .subpage-logo{
      display: none;
    }

    @include PC {
      &:hover{
        opacity: 0.6;
      }
      img{
        min-height: 107px;
      }
    }
    @include SP {
      height: 50px;
    }
  }

  @include SP {
    #logo {
      max-width: 60px;
      top: 11px;
      left: 15px;
    }

    .hamberger {
      top: 10px;
      right: 10px;
      span {
        width: 25px;
        margin-bottom: 7px;
      }
    }
  }
}

.subpage {
  .header{
    position: relative;
    padding-top: 40px;
    .hamberger{
      position: fixed;
      padding: 12px 0 0;
      // background: #fff;
      // right: 14px;
      &.active{
        padding-top: 15px;
      }
      span{
        background: #989c9d;
      }
    }
    #logo{
        position: relative;
        top: 0;
        left: 0;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 22px;
        // background: url(../img/common/logo_black.svg) no-repeat center / cover;
        img{
          display: none;
        }
        .subpage-logo{
          display: block;
        }
    }
  }
  @include SP {
    .header{
      padding-top: 20px;
      #logo{
        max-width: 60px;
        img{
          height: 51px;
        }
      }
    }
  }
}


.fixHeader {
  .hamberger {
    // background: #fff;
    // right: 14px;
    transition: .3s;
    @include SP {
      // right: 0;
    } 
  }
}

.layerMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-align: left;
  background: #fff;
  overflow: hidden;
  transition: opacity .5s 0s;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     transition: opacity .5s .2s;
  }
  padding-top: 50px;
  pointer-events: none;

  .close_layer {
    display: none;
  }

  .inLayer {
    position: relative;
    height: 100%;
    padding: 0 20px 20px;
  }

  .gNavi {
    text-align: center;
    font-weight: bold;
    padding: 50px 0 75px;
    @include letter-spacing(100);
    @include font-size(26);
    @include ffLT;

    @include SP {
      padding: 0;
    }

    li {
        margin-bottom: -4px;
      a {
        display: inline-block;
        opacity: 0;
        height: 52px;
        line-height: 52px;
        overflow: hidden;
        transform: translateY(30px);
        transition: opacity .3s .0s ease, transform .0s .3s ease;
      }

      span {
        display: inline-block;
        overflow: hidden;
        opacity: 1;

        &:after {
          @include PC{
            position: relative;
            content: attr(data-hover);
            display: block;
            @include ffYG;
            @include font-size(18);
            @include letter-spacing(200);
          }
        }
      }

      & a:hover {
        @include PC {
          span {
            transform: translateY(-52px);
            opacity: 0.5;
          }
        }
      }
    }
  }

  .close_layer {
    display: block;
    padding: 12px 0 10px;
    background: #BFBFBF;
    color: #fff;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    cursor: pointer;
  }
}

.layerOn {
  overflow: hidden;
  .layerMenu {
    opacity: 1;
    transition: opacity .5s;
    overflow: scroll;
    pointer-events: initial;
    &::-webkit-scrollbar {display: none;}
    &{-ms-overflow-style: none;}
    .gNavi {
      li {
        a {
          opacity: 1;
          transform: translateY(0);
        }

        &:nth-child(1) a  {transition: opacity 1.2s 0.20s ease, transform 1.2s 0.20s ease;}

        &:nth-child(2) a  {transition: opacity 1.2s 0.35s ease, transform 1.2s 0.35s ease;}

        &:nth-child(3) a  {transition: opacity 1.2s 0.50s ease, transform 1.2s 0.50s ease;}

        &:nth-child(4) a  {transition: opacity 1.2s 0.65s ease, transform 1.2s 0.65s ease;}

        &:nth-child(5) a  {transition: opacity 1.2s 0.80s ease, transform 1.2s 0.80s ease;}

        &:nth-child(6) a  {transition: opacity 1.2s 0.95s ease, transform 1.2s 0.95s ease;}

        &:nth-child(7) a  {transition: opacity 1.2s 1.10s ease, transform 1.2s 1.10s ease;}

        &:nth-child(8) a  {transition: opacity 1.2s 1.25s ease, transform 1.2s 1.25s ease;}

        &:nth-child(9) a  {transition: opacity 1.2s 1.40s ease, transform 1.2s 1.40s ease;}

        &:nth-child(10) a {transition: opacity 1.2s 1.55s ease, transform 1.2s 1.55s ease;}
      }
    }
  }
  .hamberger {
    span {
      background: #000;
    }
  }
}

.hamberger {
  position: fixed;
  top: 27px;
  right: 37px;
  z-index: 9999;
  width: 50px;
  height: 50px;
  padding: 12px 0;
  line-height: 1;
  font-size: 0;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  span {
    display: block;
    width: 34px;
    height: 2px;
    background: #989c9d;
    transition: .3s;
    margin: 0 auto 10px;
  }

  &.active {
    span {margin-bottom: 8px;}
    .ham {@include transform_c(rotate(45deg) translateY(7px) translateX(7px));}
    .ber {@include transform_c(rotate(-45deg));}
    .ger {display: none;}
  }
  @include max-screen(767px) {display: block;}
}